export const ZodiacData = [
  {
    zodiacdate: 'March 21 - April 19',
    title: 'Aries',
    url: 'https://i.ibb.co.com/ydrydJL/Aries.png',
    element: 'Fire',
    rulingPlanet: 'Mars',
    description: 'Bold and Ambitious: Aries is the trailblazer of the zodiac, brimming with confidence and an eagerness to lead. Represented by the Ram, Aries individuals are fearless when facing challenges, charging ahead with determination. They thrive in competitive environments and enjoy pushing boundaries to achieve their goals. Their energy is infectious, often motivating those around them to reach new heights.',
    partner: 'Leo, Sagittarius, Libra',
    desti: '/Aries'
  },
  {
    zodiacdate: 'April 20 - May 20',
    title: 'Taurus',
    url: 'https://i.ibb.co.com/99Jsm8n/Taurus.png',
    element: 'Earth',
    rulingPlanet: 'Venus',
    description: 'Grounded and Reliable: Taurus is a sign known for its steadfast nature and appreciation for life’s pleasures. Represented by the Bull, they are practical, patient, and deeply rooted in their values. Taurus individuals have a strong work ethic and enjoy building secure and comfortable lives. Their love for beauty and comfort often drives their artistic or creative pursuits.',
    partner: 'Virgo, Capricorn, Cancer',
    desti: '/Taurus'
  },
  {
    zodiacdate: 'May 21 - June 20',
    title: 'Gemini',
    url: 'https://i.ibb.co.com/jLGDQsR/Gemini.png',
    element: 'Air',
    rulingPlanet: 'Mercury',
    description: 'Curious and Versatile: Gemini is the social butterfly of the zodiac, known for its quick wit and adaptability. Represented by the Twins, this sign embodies duality, showing different facets of their personality. Gemini individuals are naturally curious and enjoy exploring new ideas, places, and people. They thrive in dynamic environments that keep their minds engaged.',
    partner: 'Libra, Aquarius, Aries',
    desti: '/Gemini'
  },
  {
    zodiacdate: 'June 21 - July 22',
    title: 'Cancer',
    url: 'https://i.ibb.co.com/PhVssYn/Cancer.png',
    element: 'Water',
    rulingPlanet: 'Moon',
    description: 'Nurturing and Intuitive: Cancer is the caretaker of the zodiac, deeply connected to their emotions and those of others. Represented by the Crab, they are protective and value their home and family above all. Cancer individuals have a strong intuition and are incredibly compassionate, always striving to create a safe and nurturing environment for their loved ones.',
    partner: 'Scorpio, Pisces, Taurus',
    desti: '/Cancer'
  },
  {
    zodiacdate: 'July 23 - August 22',
    title: 'Leo',
    url: 'https://i.ibb.co.com/0ZTt13C/Leo.png',
    element: 'Fire',
    rulingPlanet: 'Sun',
    description: 'Charismatic and Confident: Leo is the star of the zodiac, radiating warmth, charm, and vitality. Represented by the Lion, they are natural-born leaders who thrive in the spotlight. Leo individuals are creative, passionate, and generous, often inspiring those around them. They value loyalty and love to celebrate life’s joys with their friends and family.',
    partner: 'Aries, Sagittarius, Libra',
    desti: '/Leo'
  },
  {
    zodiacdate: 'August 23 - September 22',
    title: 'Virgo',
    url: 'https://i.ibb.co.com/SmzjP6V/Virgo.png',
    element: 'Earth',
    rulingPlanet: 'Mercury',
    description: 'Practical and Detail-Oriented: Virgo is the perfectionist of the zodiac, known for their analytical minds and meticulous nature. Represented by the Maiden, they are hardworking and always strive for excellence. Virgo individuals have a deep desire to be of service, often helping others with their practical advice and problem-solving abilities.',
    partner: 'Taurus, Capricorn, Cancer',
    desti: '/Virgo'
  },
  {
    zodiacdate: 'September 23 - October 22',
    title: 'Libra',
    url: 'https://i.ibb.co.com/yVdk86j/Libra.png',
    element: 'Air',
    rulingPlanet: 'Venus',
    description: 'Diplomatic and Charming: Libra is the peacemaker of the zodiac, known for their grace, fairness, and love for harmony. Represented by the Scales, they seek balance in all areas of life. Libra individuals are social, romantic, and have an eye for beauty. They enjoy meaningful conversations and thrive in partnerships where mutual understanding is key.',
    partner: 'Gemini, Aquarius, Aries',
    desti: '/Libra'
  },
  {
    zodiacdate: 'October 23 - November 21',
    title: 'Scorpio',
    url: 'https://i.ibb.co.com/vBMSfG5/Scorpio.png',
    element: 'Water',
    rulingPlanet: 'Pluto (modern), Mars (traditional)',
    description: 'Intense and Mysterious: Scorpio is the most enigmatic sign of the zodiac, known for their passion, determination, and emotional depth. Represented by the Scorpion, they are fiercely loyal and protective of their inner circle. Scorpio individuals have a magnetic presence and are often drawn to transformative experiences.',
    partner: 'Cancer, Pisces, Virgo',
    desti: '/Scorpio'
  },
  {
    zodiacdate: 'November 22 - December 21',
    title: 'Sagittarius',
    url: 'https://i.ibb.co.com/qdGjxtR/Sagittarius.png',
    element: 'Fire',
    rulingPlanet: 'Jupiter',
    description: 'Adventurous and Optimistic: Sagittarius is the explorer of the zodiac, always seeking knowledge, freedom, and new experiences. Represented by the Archer, they are enthusiastic, curious, and philosophical. Sagittarius individuals are known for their open-mindedness and love for adventure, inspiring others to broaden their horizons.',
    partner: 'Aries, Leo, Aquarius',
    desti: '/Sagittarius'
  },
  {
    zodiacdate: 'December 21 - January 20',
    title: 'Capricorn',
    url: 'https://i.ibb.co.com/bKpZ9yk/Capricorn.png',
    element: 'Earth',
    rulingPlanet: 'Saturn',
    description: 'Ambitious and Disciplined: Capricorn is the determined achiever of the zodiac, known for their strong sense of responsibility and work ethic. Represented by the Goat, they are practical, patient, and focused on long-term goals. Capricorn individuals value tradition and strive to build a stable and prosperous future for themselves and their loved ones.',
    partner: 'Taurus, Virgo, Scorpio',
    desti: '/Capricorn'
  },
  {
    zodiacdate: 'January 21 - February 18',
    title: 'Aquarius',
    url: 'https://i.ibb.co.com/cF4F1tR/Aquarius.png',
    element: 'Air',
    rulingPlanet: 'Uranus (modern), Saturn (traditional)',
    description: 'Innovative and Independent: Aquarius is the visionary of the zodiac, known for their unique perspective and progressive ideas. Represented by the Water Bearer, they are highly intellectual and value freedom and individuality. Aquarius individuals are humanitarian at heart, often striving to make the world a better place through their creativity and innovation.',
    partner: 'Gemini, Libra, Sagittarius',
    desti: '/Aquarius'
  },
  {
    zodiacdate: 'February 19 - March 20',
    title: 'Pisces',
    url: 'https://i.ibb.co.com/xH1gBZs/Pisces.png',
    element: 'Water',
    rulingPlanet: 'Neptune (modern), Jupiter (traditional)',
    description: 'Empathetic and Dreamy: Pisces is the compassionate dreamer of the zodiac, deeply in tune with their emotions and the world around them. Represented by the Fish, they have a rich imagination and a strong spiritual connection. Pisces individuals are intuitive, artistic, and often find solace in creative expression and meaningful relationships.',
    partner: 'Cancer, Scorpio, Capricorn',
    desti: '/Pisces'
  }
]
