<template>
    <div class="numerology-app">
      <div class="header">
        <h1>Chaldean Numerology Calculator</h1>
        <p>Discover your Destiny, Soul Urge, and Dream Numbers.</p>
      </div>

      <form class="numerology-form" @submit.prevent="calculateNumerology">
        <label for="fullname">Enter Your Full Name:</label>
        <br>
        <input
          id="fullname"
          v-model="fullName"
          placeholder="Type your full name here..."
          required
        />
        <br>
        <button type="submit">Calculate</button>
      </form>

      <div v-if="isLoading" class="loading">
        <div class="spinner"></div>
        <p>Calculating your numerology...</p>
      </div>

      <div v-else-if="result" class="result">
        <h2>Your Numerology Results</h2>
        <p><strong>Life Number:</strong> {{ result.lifeNumber }} ({{ result.title }})</p>
        <p><strong>Destiny Description:</strong> {{ result.destiny }}</p>
        <p><strong>Soul Urge Number:</strong> {{ result.soulUrgeNumber }}</p>
        <p><strong>Soul Urge Description:</strong> {{ result.soulurge }}</p>
        <p><strong>Dream Number:</strong> {{ result.dreamNumber }}</p>
        <p><strong>Dream Description:</strong> {{ result.dreamnums }}</p>
      </div>
    </div>
  </template>

<script>
import { Chaldean } from '@/Chaldean'

export default {
  data () {
    return {
      fullName: '',
      isLoading: false,
      result: null
    }
  },
  methods: {
    calculateNumerology () {
      this.isLoading = true
      this.result = null

      setTimeout(() => {
        const lifeNumber = this.calculateLifeNumber(this.fullName)
        const soulUrgeNumber = this.calculateSoulUrgeNumber(this.fullName)
        const dreamNumber = this.calculateDreamNumber(this.fullName)

        const lifeData = Chaldean.find((entry) => entry.LifeNumber === lifeNumber)
        this.result = {
          lifeNumber,
          soulUrgeNumber,
          dreamNumber,
          ...lifeData
        }

        this.isLoading = false
      }, 2000)
    },
    calculateLifeNumber (name) {
      const chaldeanMap = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 8, G: 3, H: 5, I: 1, J: 1, K: 2, L: 3, M: 4, N: 5, O: 7, P: 8, Q: 1, R: 2, S: 3, T: 4, U: 6, V: 6, W: 6, X: 5, Y: 1, Z: 7 }

      const sum = name
        .toUpperCase()
        .replace(/[^A-Z]/g, '')
        .split('')
        .reduce((total, char) => total + (chaldeanMap[char] || 0), 0)

      return this.reduceToSingleDigit(sum)
    },
    calculateSoulUrgeNumber (name) {
      const chaldeanMap = { A: 1, E: 5, I: 1, O: 7, U: 6 }
      const vowels = 'AEIOU'

      const sum = name
        .toUpperCase()
        .split('')
        .filter((char) => vowels.includes(char))
        .reduce((total, char) => total + (chaldeanMap[char] || 0), 0)

      return this.reduceToSingleDigit(sum)
    },
    calculateDreamNumber (name) {
      const chaldeanMap = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 8, G: 3, H: 5, I: 1, J: 1, K: 2, L: 3, M: 4, N: 5, O: 7, P: 8, Q: 1, R: 2, S: 3, T: 4, U: 6, V: 6, W: 6, X: 5, Y: 1, Z: 7 }
      const vowels = 'AEIOU'

      const sum = name
        .toUpperCase()
        .split('')
        .filter((char) => !vowels.includes(char))
        .reduce((total, char) => total + (chaldeanMap[char] || 0), 0)

      return this.reduceToSingleDigit(sum)
    },
    reduceToSingleDigit (num) {
      while (num > 9 && num !== 11 && num !== 22) {
        num = num
          .toString()
          .split('')
          .reduce((a, b) => a + parseInt(b, 10), 0)
      }
      return num
    }
  }
}
</script>

  <style scoped>
  .numerology-app {
    font-family: 'Georgia', serif;
    color: #333;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    background: linear-gradient(135deg, #fdfcfb, #e2d1c3);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .header {
    margin-bottom: 30px;
  }

  .header h1 {
    font-size: 2.5em;
    color: #704214;
  }

  .header p {
    font-size: 1.2em;
  }

  .numerology-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .numerology-form input {
    padding: 10px;
    width: 70%;
    border: 2px solid #704214;
    border-radius: 5px;
    font-size: 1em;
  }

  .numerology-form button {
    padding: 10px 20px;
    background: #704214;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }

  .loading {
    font-size: 1.5em;
    color: #704214;
  }

  .spinner {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #704214;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .result {
    text-align: left;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .result h2 {
    color: #704214;
  }
  </style>
