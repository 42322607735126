export const Chaldean = [
  {
    LifeNumber: 1,
    title: 'The Trailblazer',
    destiny: 'Those with a Destiny Number 1 are natural-born leaders. They are ambitious, innovative, and driven to stand out in life. Often, they possess a pioneering spirit, ready to take on challenges and blaze new trails. Their charisma inspires others, making them great motivators. However, they may struggle with arrogance or an unwillingness to accept help. Success often comes through their ability to maintain focus and determination. They are destined to create and lead in their chosen field.',
    soulurge: 'A Soul Urge 1 individual craves independence and self-expression. Deep down, they have an intense desire to be recognized for their uniqueness and abilities. They value personal achievements and often strive for excellence in everything they do. This urge can drive them to constantly improve and innovate. They may also feel restless when restricted or unable to take the lead. Fulfillment comes from pursuing their ambitions and staying true to their inner drive for success.',
    dreamnums: 'With a Dream Number 1, the individual aspires to be a leader or someone who sets trends rather than follows them. Their dreams often involve being in positions of power or influencing others in meaningful ways. They envision themselves as trailblazers who make a lasting impact. This number reflects their desire for individuality and recognition in their field. They dream of a life filled with achievements and pioneering efforts. Their aspirations often lead them to take bold steps toward their goals.'
  },
  {
    LifeNumber: 2,
    title: 'The Harmonizer',
    destiny: 'People with a Destiny Number 2 are the harmonizers and mediators of the world. They excel at bringing people together, fostering understanding, and resolving conflicts. Their sensitive and diplomatic nature makes them excellent at teamwork and partnerships. They are destined to create peace and balance wherever they go. However, they might struggle with indecision or an overreliance on others. Their journey often involves learning to trust their intuition and finding their voice in group dynamics.',
    soulurge: 'A Soul Urge 2 individual longs for deep emotional connections and harmony in relationships. They feel most fulfilled when surrounded by love and mutual understanding. Their empathetic nature makes them crave nurturing environments where they can support and be supported. They dislike confrontation and may go to great lengths to maintain peace. However, they must learn not to suppress their own needs for the sake of others. Their true happiness lies in balanced and loving partnerships.',
    dreamnums: 'Those with a Dream Number 2 envision a life of harmony, cooperation, and mutual respect. They often dream of fostering strong connections with others or creating environments that bring people together. Their ideal life involves collaboration, whether in personal relationships or professional settings. They dream of being a peacemaker and a trusted confidant. Their aspirations often include nurturing bonds and achieving emotional fulfillment through teamwork.'
  },
  {
    LifeNumber: 3,
    title: 'The Inspirer',
    destiny: 'A Destiny Number 3 signifies creativity, optimism, and self-expression. People with this number are often gifted communicators, artists, or performers who thrive on connecting with others. Their enthusiasm and charm make them magnetic personalities who inspire joy. They are destined to bring light and positivity into the world through their creative talents. However, they can sometimes struggle with scattered energy or lack of focus. Their path often involves channeling their creativity into meaningful and impactful pursuits.',
    soulurge: 'Soul Urge 3 individuals have a deep desire to express themselves creatively and emotionally. They yearn to bring joy and laughter to others and often thrive in social environments. Their soul feels most alive when they are free to explore their imagination and share their ideas. They may struggle with boredom or dissatisfaction if their lives lack creative outlets. Fulfillment comes from embracing their artistic abilities and finding ways to inspire others through their talents.',
    dreamnums: 'With a Dream Number 3, individuals aspire to live a vibrant, creative, and joyful life. They dream of being in the spotlight, whether as artists, writers, or entertainers. Their visions often involve spreading happiness and inspiring others through their creativity. They aspire to live a life full of fun, adventure, and meaningful connections. Their dreams also reflect their desire to turn their talents into something tangible and celebrated by others.'
  },
  {
    LifeNumber: 4,
    title: 'The Foundation Builder',
    destiny: ' Individuals with a Destiny Number 4 are the builders and organizers of the world. They excel at creating strong foundations, whether in their careers, relationships, or communities. With their disciplined and methodical approach, they are destined to bring order and structure wherever they go. These individuals value stability and often strive for long-term success through hard work. However, they may struggle with rigidity or resistance to change. Their path often involves learning to balance practicality with flexibility.',
    soulurge: 'A Soul Urge 4 individual craves security, stability, and a sense of accomplishment. They feel most at peace when their lives are structured and grounded. They yearn to create environments that are reliable and supportive, both for themselves and for others. However, they can sometimes become overly focused on work or perfectionism. True fulfillment comes from achieving balance between their practical goals and emotional well-being.',
    dreamnums: 'With a Dream Number 4, individuals aspire to build a life of stability, order, and reliability. They dream of achieving success through hard work and perseverance. Their visions often include creating something lasting and meaningful, such as a stable home or a successful career. They seek to be known for their dependability and contributions to society. Their dreams reflect a desire for a life that is both productive and deeply rooted in practicality.'
  },
  {
    LifeNumber: 5,
    title: 'The Adventurer',
    destiny: 'A Destiny Number 5 represents freedom, adventure, and adaptability. These individuals are natural explorers who thrive on change and new experiences. They are destined to inspire others through their curiosity and zest for life. Their adaptability allows them to excel in various situations and careers. However, they may struggle with restlessness or commitment issues. Their life path often involves balancing their desire for freedom with the need for stability.',
    soulurge: 'Soul Urge 5 individuals deeply crave freedom, variety, and excitement. They feel most fulfilled when exploring new places, ideas, or opportunities. They thrive in environments that allow them to express their individuality and embrace adventure. However, they may become easily bored or distracted if their lives lack stimulation. True happiness comes from finding a balance between their need for freedom and their responsibilities.',
    dreamnums: 'Those with a Dream Number 5 envision a life full of adventure, travel, and exploration. They dream of breaking free from routines and living life on their terms. Their ideal life involves embracing change and experiencing the world in all its diversity. They aspire to inspire others through their daring and unconventional pursuits. Their dreams reflect a longing for excitement and a life filled with new discoveries.'
  },
  {
    LifeNumber: 6,
    title: 'The Protector',
    destiny: 'A Destiny Number 6 signifies responsibility, compassion, and nurturing. These individuals are natural caregivers who are destined to create harmony and support others. They excel in roles that involve helping, teaching, or guiding. Their sense of responsibility often extends to their families and communities. However, they may struggle with overcommitment or perfectionism. Their life path involves learning to care for themselves while nurturing others.',
    soulurge: 'Soul Urge 6 individuals have an intense desire to create loving and harmonious relationships. They feel most at peace when they are surrounded by family and friends in a nurturing environment. Their empathetic nature drives them to care deeply about others. However, they may feel overwhelmed by their sense of duty. Fulfillment comes from balancing their own needs with their desire to serve others.',
    dreamnums: 'With a Dream Number 6, individuals aspire to create a life of love, harmony, and community. They dream of a peaceful home, strong relationships, and a life filled with compassion. Their ideal vision often includes helping others and making the world a better place. They seek to be seen as kind, supportive, and dependable. Their dreams reflect their longing for a life of purpose and emotional fulfillment.'
  },
  {
    LifeNumber: 7,
    title: 'The Seeker of Truth',
    destiny: 'Individuals with a Destiny Number 7 are seekers of truth and wisdom. They are destined to delve deep into the mysteries of life and uncover spiritual or intellectual truths. Their analytical and introspective nature often makes them philosophers, researchers, or spiritual guides. However, they may struggle with loneliness or emotional detachment. Their path involves balancing their inner world with meaningful connections to others.',
    soulurge: 'A Soul Urge 7 individual longs for spiritual growth, knowledge, and understanding. They feel most fulfilled when exploring lifes deeper questions and connecting with their inner self. They value solitude and introspection, which allows them to recharge and reflect. However, they may struggle with opening up emotionally. True fulfillment comes from combining their quest for wisdom with genuine relationships.',
    dreamnums: 'Those with a Dream Number 7 envision a life of intellectual and spiritual enlightenment. They dream of uncovering hidden truths and mastering complex concepts. Their ideal life often involves deep study, meditation, or exploration of the unknown. They aspire to be recognized for their wisdom and insight. Their dreams reflect a longing for peace, understanding, and inner harmony.'
  },
  {
    LifeNumber: 8,
    title: 'The Visionary Leader',
    destiny: 'A Destiny Number 8 signifies ambition, authority, and material success. These individuals are destined to achieve great things in life, often excelling in business, leadership, or finance. They have a strong drive to manifest abundance and create systems of power and influence. However, they may struggle with work-life balance or an overemphasis on material gains. Their journey involves using their success to help others and balancing their ambitions with ethical principles.',
    soulurge: ' A Soul Urge 8 individual desires power, success, and recognition. They feel most fulfilled when they are in control of their destiny and achieving tangible results. Their soul thrives on challenges and the opportunity to leave a lasting legacy. However, they may feel frustrated if they are unable to achieve their goals. True happiness comes from using their influence responsibly and finding deeper meaning beyond material success.',
    dreamnums: 'With a Dream Number 8, individuals aspire to a life of power, wealth, and achievement. They dream of reaching the pinnacle of their careers and being respected for their leadership. Their visions often include building empires, whether in business, community, or personal ventures. They seek to be seen as influential and capable, leaving behind a legacy of success. Their dreams reflect their desire for mastery and material fulfillment.'
  },
  {
    LifeNumber: 9,
    title: 'The Compassionate Sage',
    destiny: 'A Destiny Number 9 signifies compassion, humanitarianism, and universal love. These individuals are destined to serve humanity and bring about positive change. They are often drawn to careers or causes that involve helping others on a large scale. Their altruistic nature makes them natural leaders in philanthropic efforts. However, they may struggle with letting go of the past or feeling overly idealistic. Their journey involves balancing personal fulfillment with their desire to uplift others.',
    soulurge: 'A Soul Urge 9 individual longs to make a difference in the world. They feel most alive when they are helping others, contributing to society, or advocating for justice. Their soul craves a deeper purpose and often seeks spiritual or humanitarian pursuits. However, they may feel burdened by the weight of their empathy. Fulfillment comes from channeling their compassion into meaningful action while maintaining self-care.',
    dreamnums: 'With a Dream Number 9, individuals aspire to a life of service, compassion, and global impact. They dream of making a difference in the world, whether through charity, activism, or leadership. Their ideal vision involves helping others and creating a more harmonious world. They seek to be seen as wise, loving, and selfless. Their dreams reflect their desire to leave a legacy of kindness and purpose.'
  },
  {
    LifeNumber: 11,
    title: 'The Spiritual Beacon',
    destiny: 'A Destiny Number 11 signifies spiritual insight, intuition, and inspiration. These individuals are destined to be visionaries who uplift others through their higher awareness and creativity. They often possess a deep connection to their intuition and a natural ability to guide others spiritually. However, they may struggle with self-doubt or feeling overwhelmed by their potential. Their journey involves channeling their unique gifts into meaningful contributions to the world.',
    soulurge: 'A Soul Urge 11 individual longs for spiritual enlightenment and a connection to the divine. They feel most fulfilled when they are inspiring others and following their intuition. Their soul craves higher purpose and often seeks mystical or creative outlets. However, they may experience anxiety or fear of failure. True happiness comes from trusting their inner voice and embracing their role as a spiritual guide.',
    dreamnums: 'With a Dream Number 11, individuals aspire to a life of inspiration and spiritual growth. They dream of being visionaries, leaders, or creative forces who make a profound impact on others. Their ideal life involves embracing their higher calling and living authentically. They seek to be seen as intuitive, wise, and transformative. Their dreams reflect their desire to shine a light on truth and uplift humanity.'
  },
  {
    LifeNumber: 22,
    title: 'The Grand Architect',
    destiny: 'A Destiny Number 22 signifies practicality combined with vision. These individuals are destined to create large-scale projects that benefit humanity. They possess the rare ability to turn dreams into reality, often leaving behind a legacy of tangible achievements. However, they may struggle with self-imposed pressure or fear of failure. Their path involves balancing their grand vision with the discipline needed to see it through.',
    soulurge: 'A Soul Urge 22 individual longs to create something significant and lasting. They feel most fulfilled when they are working toward a purpose that impacts many people. Their soul craves meaningful work that combines practicality with idealism. However, they may feel overwhelmed by the enormity of their ambitions. Fulfillment comes from breaking their vision into manageable steps and trusting their abilities.',
    dreamnums: 'With a Dream Number 22, individuals aspire to a life of accomplishment and global impact. They dream of building systems, organizations, or structures that leave a lasting legacy. Their ideal life involves using their talents to benefit others on a large scale. They seek to be seen as capable, visionary, and influential. Their dreams reflect their desire to turn the impossible into the possible for the greater good.'
  }
]
